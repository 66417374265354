<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div v-if="role != 'SUPERADMIN'" class="px-5">
      <div
        class="mt-2 rounded-lg"
        style="border: 1px solid #cdd0d1; overflow: hidden"
      >
        <v-card
          flat
          class="rounded-t-lg"
          rounded="0"
          color="#052633"
          min-height="25vh"
        >
          <v-row no-gutters v-if="!success_complaint">
            <v-col cols="12" md="7" class="d-flex align-center">
              <div class="pa-5 d-flex align-start justify-center flex-column">
                <h1 class="txt_white">
                  Tuliskan Pengaduan atau Laporan Anda Disini.
                </h1>
                <h4 class="not_bold txt_white">
                  Isi pengaduan anda di bagian kolom dibawah.
                </h4>
              </div>
            </v-col>
            <v-col cols="12" md="5" class="d-flex align-center justify-end">
              <img
                class="px-5 pt-4"
                src="../../assets/img/complaint.png"
                style="object-fit: contain"
              />
            </v-col>
          </v-row>
        </v-card>
        <div
          v-if="!success_complaint"
          class="my-4 d-flex align-center justify-center"
        >
          <div class="_60_w">
            <div>
              <h4>Pilih kategori pengaduan anda</h4>
              <v-select
                placeholder="Silakan pilih kategori pengaduan"
                outlined
                hide-details
                :items="optComplaint"
                item-text="name"
                item-value="id"
                v-model="field.category_complaint"
                dense
                class="rounded-lg"
              ></v-select>
            </div>
            <div class="mt-3">
              <h4>Tuliskan pengaduan anda</h4>
              <v-textarea
                placeholder="Silakan isi nama event disini"
                v-model="field.complaint"
                rows="3"
                auto-grow
                color="#0D47A1"
                dense
                hide-details
                outlined
                class="rounded-lg"
              ></v-textarea>
            </div>
            <div class="mt-3">
              <h4>Attachment</h4>
              <v-file-input
                v-model="field.file"
                label="Silakan klik disini untuk menambahkan file"
                outlined
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                dense
                hide-details
                class="rounded-lg"
              ></v-file-input>
            </div>
            <div class="mt-5 d-flex align-center justify-end">
              <v-btn
                :disabled="!loading ? false : true"
                depressed
                class="text-capitalize bg_color1"
                @click="sendComplaint"
              >
                <h4 class="txt_white" v-if="!loading">Kirim</h4>
                <h4 class="txt_white" v-if="loading">Loading...</h4>
              </v-btn>
            </div>
          </div>
        </div>
        <div
          v-if="success_complaint"
          class="mb-4 d-flex align-center justify-center flex-column"
          style="margin-top: -150px"
        >
          <img
            src="../../assets/img/success complaint.png"
            height="250px"
            style="object-fit: contain; z-index: 2"
          />
          <div class="text-center my-5">
            <h1 class="txt_color_default">
              Terima kasih telah mengirimkan pengaduan anda.
            </h1>
            <h4 class="not_bold" style="color: #697d85">
              Pengaduan anda terkirim, mohon menunggu sampai kami mengirimkan
              respon. Silakan untuk kembali ke halaman dashboard.
            </h4>
            <v-btn to="/" depressed class="text-capitalize bg_color1 mt-5" dark
              >Dashboard</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="role == 'SUPERADMIN'">
      <v-row no-gutters class="px-5">
        <v-col cols="12" md="8">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for Content"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-end">
          <div class="mt-2 mr-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
          <div class="mt-2 d-flex align-center">
            <v-menu
              v-model="menuKategori"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-select
                  v-bind="attrs"
                  v-on="on"
                  :items="optComplaint"
                  item-text="name"
                  item-value="id"
                  v-model="pengaduan_kategori"
                  placeholder="Kategori"
                  dense
                  flat
                  hide-details
                  solo
                  outlined
                  style="width: 150px"
                  @change="fetchData"
                ></v-select>
              </template>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4 px-5" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="List Pengaduan" />
        </v-col>
      </v-row>
      <div>
        <v-skeleton-loader
          class="pa-2 elevation-0"
          type="table-tbody"
          v-if="loading"
        ></v-skeleton-loader>
        <div v-if="!loading && dataSet">
          <v-data-table
            :headers="headers"
            :items="dataSet"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
            :single-select="false"
            class="elevation-0"
          >
            <template v-slot:[`item.pengaduan_kategori_id`]="{ item }">
              <div
                class="d-flex"
                v-for="(kategory, n) in optComplaint"
                :key="n"
              >
                <h4
                  class="not_bold"
                  v-if="kategory.id == item.pengaduan_kategori_id"
                >
                  {{ kategory.name }}
                </h4>
              </div>
            </template>
            <template v-slot:[`item.attachment`]="{ item }">
              <div>
                <h4 class="not_bold">
                  {{ item.attachment }}
                </h4>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                class="rounded-lg pa-2 d-flex align-center justify-center"
                dark
                flat
                :color="item.status == 0 ? '#DC3545' : '#26AF65'"
              >
                <h4 class="not_bold">
                  {{
                    item.status == 0 ? "Belum Diperbaiki" : "Sudah Diperbaiki"
                  }}
                </h4>
              </v-card>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      depressed
                      class="mr-2"
                      @click="updateData(item)"
                    >
                      <v-icon dark small> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Status Pengaduan</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="item.attachment?false:true"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      depressed
                      class="mr-2"
                      @click="goTolink(item.attachment)"
                      target="blank"
                    >
                      <v-icon size="22"> mdi-attachment-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Lihat Attachment</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      depressed
                      class="mr-2"
                      @click="deleteData(item)"
                    >
                      <v-icon color="red" small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Pengaduan</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pa-3">
            <v-pagination
              color="#052633"
              v-model="page"
              @input="fetchData"
              :length="last_page"
            ></v-pagination>
          </div>
        </div>
      </div>
      <div class="pa-2">
        <dialog-medium
          :d_medium="d_create"
          :type="d_type"
          @close="d_create = false"
          :title="d_title"
          :inventory="inventory"
          :line="line"
          @refetch="fetchData"
        />
        <dialog-small
          :d_small="d_small"
          @refetch="fetchData"
          @close="d_small = false"
          :inventory="inventory"
          :type="d_type"
          :title="d_title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { NavbarProfile, Bullet },
  name: "complaintPage",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      menuLimit: false,
      menuKategori: false,
      find: "",
      pengaduan_kategori: "",
      loading: false,
      dataSet: null,
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Halaman Pengaduan",
          disabled: true,
          href: "#",
        },
      ],
      optComplaint: [],
      field: {
        category_complaint: "",
        complaint: "",
        file: null,
      },
      success_complaint: false,
      headers: [
        { text: "Pengaduan Kategori", value: "pengaduan_kategori_id" },
        { text: "Konten", value: "konten" },
        { text: "Attachment", value: "attachment" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchDataKategori();
  },
  methods: {
    sendComplaint() {
      this.loading = true;
      let body = [
        { pengaduan_kategori_id: this.field.category_complaint },
        { konten: this.field.complaint },
        { attachment: this.field.file },
      ];
      let data = {
        body: body,
        path: `pengaduan/create`,
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
            for (let i in this.form) {
              if(i != 'file'){
                this.form[i] = "";
              }else{
                this.form[i] = null;
              }
            }
            this.success_complaint = true;
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `pengaduan/list?page[size]=${this.limit}&page[number]=${this.page}&filter[pengaduan_kategori_id]=${this.pengaduan_kategori}&filter[konten]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchDataKategori() {
      this.loading = true;
      let data = {
        path: `pengaduan/kategori`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.optComplaint = data;
          if (this.role == "SUPERADMIN") {
            this.optComplaint.push({ name: "All", id: "" });
            this.fetchData();
          }else{
            this.loading = false;
          }
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    updateData(item) {
      this.inventory = item;
      this.d_type = "u_complaint";
      this.d_title = "Edit Status Pengaduan";
      this.d_create = true;
      this.line = true;
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `pengaduan/delete`,
        deleteType: "Complaint",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Pengaduan";
      this.d_small = true;
      this.line = true;
    },
    goTolink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped></style>
